import React, { useEffect, useState } from "react";
import { disease, patient, profession } from "../../types/patient";
import "./home.scss";
import defaultAvatar from "../../assets/default_avatar.jpg";
import {
  getDiseases,
  getPatientBio,
  getPatientList,
  getPatientSuggestion,
  getProfessions,
} from "../../api";
import SearchInput from "../../components/SearchInput";
import {
  SVG_Allergy,
  SVG_BriefCase,
  SVG_Rocket,
  SVG_Wiping,
} from "../../assets/svgs";
import CheckBox from "../../components/CheckBox";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import LazyLoad from "react-lazyload";
import InputSelect from "../../components/InputSelect";

export default function Home() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchNameString, setSearchNameString] = useState<string>("");
  const [professionData, setProfessionData] = useState<Array<profession>>([]);
  const [professionString, setProfessionString] = useState("");
  const [selectedProfession, setSelectedProfession] =
    useState<profession | null>({
      profession_code: "",
      profession: "",
      note: "",
    });
  const [diseaseString, setDiseaseString] = useState("");
  const [diseaseData, setDiseaseData] = useState<Array<disease>>([]);
  const [selectedDisease, setSelectedDisease] = useState<disease | null>({
    disease_code: "",
    disease: "",
    note: "",
    fatal: "",
  });
  const [onlyFatal, setOnlyFatal] = useState<boolean>(false);
  const [fromYear, setFromYear] = useState("");
  const [toYear, setToYear] = useState("");

  const [loadingPatientData, setLoadingPatientData] = useState(false);
  const [patientData, setPatientData] = useState<Array<patient>>([]);

  const [loadingPatientBio, setLoadingPatientBio] = useState(false);
  const [patientInfo, setPatientInfo] = useState<patient>();

  useEffect(() => {
    getData();
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded && selectedProfession) searchPatientData();
  }, [selectedProfession]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoaded && selectedDisease) searchPatientData();
  }, [selectedDisease]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoaded) searchPatientData();
  }, [onlyFatal]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    const professions = getProfessions();
    const diseases = getDiseases();
    Promise.all([professions, diseases]).then((result) => {
      setProfessionData(result[0]);
      setDiseaseData(result[1]);
    });
  };

  const searchPatientData = async () => {
    setLoadingPatientData(true);
    const data = await getPatientList(
      searchNameString,
      selectedProfession?.profession_code || "",
      selectedDisease?.disease_code || "",
      onlyFatal ? "Y" : "",
      fromYear ? parseInt(fromYear, 10) : "",
      toYear ? parseInt(toYear, 10) : ""
    );
    setPatientData(data);
    setLoadingPatientData(false);
  };

  const resetAll = () => {
    setSearchNameString("");
    setSelectedProfession(null);
    setProfessionString("");
    setSelectedDisease(null);
    setDiseaseString("");
    setOnlyFatal(false);
    setFromYear("");
    setToYear("");

    setPatientData([]);
    setPatientInfo(undefined);
  };

  const getPatientInfo = async (patient_id: number) => {
    setLoadingPatientBio(true);
    const data = await getPatientBio(patient_id);
    setPatientInfo(data);
    setLoadingPatientBio(false);
  };

  return (
    <div className="home-page lg-body mx-auto">
      <h1>VIPatients</h1>
      <div className="flex flex-sm-col">
        <div className="home__wrapper home__search_wrapper">
          <div className="home__wrapper_header flex items-center justify-between">
            <p className="home__wrapper_title">Search</p>
          </div>
          <div className="home__wrapper_content">
            <p>Interested in someone in particular?</p>
            <SearchInput
              className="home__patient_search"
              placeholder="Find person by name"
              api={getPatientSuggestion}
              value={searchNameString}
              onChange={(val) => setSearchNameString(val)}
              onSelectOption={({ value }) => getPatientInfo(value)}
            />
            <p>Or discover famous patients using filters below...</p>
            <div className="home__select_container">
              <div className="home__select_header flex items-center">
                {SVG_BriefCase}
                <p>Profession</p>
              </div>
              <InputSelect
                className="home__select_box"
                placeholder="Select profession"
                disabled={loadingPatientData}
                value={professionString}
                options={professionData.map((item) => ({
                  value: item.profession_code,
                  text: item.profession,
                }))}
                onChange={(e) => setProfessionString(e)}
                onSelectOption={(option) => {
                  setSelectedProfession(
                    option
                      ? {
                          profession_code: option.value,
                          profession: option.text,
                          note: null,
                        }
                      : null
                  );
                }}
              />
            </div>
            <div className="home__select_container">
              <div className="home__select_header flex items-center">
                {SVG_Allergy}
                <p>Disease</p>
              </div>
              <InputSelect
                className="home__select_box"
                placeholder="Select disease"
                disabled={loadingPatientData}
                value={diseaseString}
                options={diseaseData.map((item) => ({
                  value: item.disease_code,
                  text: item.disease,
                }))}
                onChange={(e) => setDiseaseString(e)}
                onSelectOption={(option) => {
                  setSelectedDisease(
                    option
                      ? {
                          disease_code: option.value,
                          disease: option.text,
                          note: null,
                          fatal: "",
                        }
                      : null
                  );
                }}
              />
            </div>
            <CheckBox
              className="home__fatal_checkbox"
              checked={onlyFatal}
              disabled={loadingPatientData}
              onChange={() => setOnlyFatal(!onlyFatal)}
              label="Only fatal"
            />
            <div className="home__year_wrapper flex items-center">
              <p>Year of death</p>
              <input
                className="home__year_input"
                type="number"
                placeholder="From"
                value={fromYear}
                onChange={(e) => setFromYear(e.target.value)}
              />
              <input
                className="home__year_input"
                type="number"
                placeholder="To"
                value={toYear}
                onChange={(e) => setToYear(e.target.value)}
              />
            </div>
            <div className="flex items-center">
              <Button
                className="button button-primary home__search_btn"
                loading={loadingPatientData}
                onClick={() => searchPatientData()}
              >
                {SVG_Rocket}
                <span>
                  <b>RUN</b>
                </span>
              </Button>
              <Button
                className="button button-primary home__search_btn"
                disabled={loadingPatientData}
                onClick={() => resetAll()}
              >
                {SVG_Wiping}
                <span>Reset</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="home__wrapper home__result_wrapper">
            <div className="home__wrapper_header flex items-center justify-between">
              <p className="home__wrapper_title">Results</p>
            </div>
            <div className="home__wrapper_content">
              <p>Click on the name to view medical history</p>
              <div
                className={`home__result_container ${
                  loadingPatientBio ? "disabled" : ""
                }`}
              >
                {patientData.map((item: patient, index) => (
                  <div
                    className={`home__result_item flex items-center cursor-pointer ${
                      patientInfo && patientInfo.patient_id === item.patient_id
                        ? "active"
                        : ""
                    }`}
                    key={index}
                    onClick={() => getPatientInfo(item.patient_id)}
                  >
                    {item.first_name} {item.last_name}
                  </div>
                ))}
              </div>
              <p>
                Total:&nbsp;
                {String(patientData.length).replace(/(.)(?=(\d{3})+$)/g, "$1,")}
                &nbsp;listed
              </p>
            </div>
          </div>
          <div className="home__wrapper home__info_wrapper">
            <div className="home__wrapper_header flex items-center justify-between">
              <p className="home__wrapper_title">Medical History</p>
            </div>
            {(loadingPatientBio || patientInfo) && (
              <div className="home__info_content flex">
                {loadingPatientBio ? (
                  <Spinner />
                ) : (
                  patientInfo && (
                    <>
                      <div className="home__patient_photo">
                        {patientInfo.image_url ? (
                          <LazyLoad placeholder={<Spinner />}>
                            <img
                              src={patientInfo.image_url}
                              alt="avatar"
                              className="w-full h-full"
                            />
                          </LazyLoad>
                        ) : (
                          <img
                            src={defaultAvatar}
                            alt="avatar"
                            className="w-full h-full"
                          />
                        )}
                      </div>
                      <div className="flex-1">
                        <div className="home__info_block">
                          <p>Patient name:</p>
                          <p>
                            {patientInfo.first_name} {patientInfo.last_name}
                          </p>
                        </div>
                        <div className="home__info_block">
                          <p>
                            <p>
                              Sex:{" "}
                              <strong>
                                {patientInfo.sex === "f"
                                  ? "Female"
                                  : patientInfo.sex === "m"
                                  ? "Male"
                                  : patientInfo.sex}
                              </strong>
                            </p>{" "}
                          </p>
                        </div>

                        <div className="home__info_block">
                          <p>Profession:</p>
                          <p>
                            {patientInfo.professions.map((item, index) => (
                              <span key={index}>
                                {item.profession}
                                {item.note && ` (${item.note})`}
                              </span>
                            ))}
                          </p>
                        </div>
                        {patientInfo.age && (
                          <div className="home__info_block">
                            <p>
                              <p>
                                Age at death: <strong>{patientInfo.age}</strong>
                              </p>{" "}
                            </p>
                          </div>
                        )}
                        {patientInfo.death_year && (
                          <div className="home__info_block">
                            <p>
                              <p>
                                Died: <strong>{patientInfo.death_year}</strong>
                              </p>{" "}
                            </p>
                          </div>
                        )}
                        {patientInfo.diseases.filter(
                          (item) => item.fatal === "Y"
                        ).length > 0 && (
                          <div className="home__info_block">
                            <p>Cause of death:</p>
                            <p>
                              {patientInfo.diseases
                                .filter((item) => item.fatal === "Y")
                                .map((item, index) => (
                                  <span key={index}>
                                    {item.disease}
                                    {item.note && ` - ${item.note}`}
                                  </span>
                                ))}
                            </p>
                          </div>
                        )}
                        {patientInfo.diseases.filter(
                          (item) => item.fatal === "N"
                        ).length > 0 && (
                          <div className="home__info_block">
                            <p>Other conditions:</p>
                            <p>
                              {patientInfo.diseases
                                .filter((item) => item.fatal === "N")
                                .map((item, index) => (
                                  <span key={index}>
                                    {item.disease}
                                    {item.note && ` - ${item.note}`}
                                  </span>
                                ))}
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
